import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import { REF_BASE_URL } from "./ref_config";
import Loader from "../pages/loader";
const RefProfile = () => {
  const [tableData, setTableData] = useState([]);
  const [dataa, setDataa] = useState("");
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("rid");
  var websess = localStorage.getItem("web");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    axios
      .post(REF_BASE_URL + "get_profile.php", { id: aid })
      .then(function (data) {
        console.log(data);
        setTableData(data.data.result);
        setState({
          aname: data.data.result.name,
          email: data.data.result.email,
        });
        setIsLoading(false);
      });
    // const data = await response.json();
    Web();
  }, []);

  var sesspass = localStorage.getItem("pass");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");

  const [today, setToday] = useState("");
  const [months, setMonths] = useState("");
  const [years, setYears] = useState("");
  const [yearsall, setYearsall] = useState("");
  const Web = () => {
    axios
      .post(REF_BASE_URL + "earning.php", { id: aid })
      .then(function (data) {
        setMonths(data.data.withdraw);
        setYearsall(data.data.yearall);
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  //console.log(tableData)

  const initialState = {
    aname: "",
    email: "",
  };

  const [state, setState] = useState(initialState);
  const { aname, email } = state;

  let name, value;
  const handler = (e) => {
    name = e.target.name;
    value = e.target.value;
    setState({ ...state, [name]: value });
  };

  //console.log(email)

  const withdrawrequest = () => {
    const withdrawAmount = yearsall - months;
    axios
      .post(REF_BASE_URL + "withdraw.php", {
        id: aid,
        amount: withdrawAmount,
      })
      .then(function (data) {
        console.log(data);
        if (data.data.message == "radha") {
          toast.success("Withdraw Request Submited", {
            position: toast.POSITION.TOP_RIGHT,
          });
          $(".btn").removeClass("disabled");
        } else {
          toast.error("Try Again", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const Check = (e) => {
    $(".btn").addClass("disabled");

    e.preventDefault();

    axios
      .post(REF_BASE_URL + "edit_profile.php", {
        id: aid,
        email: email,
        name: aname,
      })
      .then(function (data) {
        //console.log(data);
        setState({ aname: "", email: "" });
        toast.success("Profile Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        $(".btn").removeClass("disabled");
      });
  };

  //console.log(tableData)

  const margin = localStorage.getItem("margin");

  return (
   
    <div>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            {yearsall - months >= 500 ? (
              <div class="modal-body">
                You want to withdraw your earning ₹ {yearsall}?
              </div>
            ) : (
              <div class="modal-body">
                You can not withdraw your earning is ₹ {yearsall - months}
              </div>
            )}
            <div class="modal-footer">
              {yearsall - months >= 500 ? (
                <>
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => withdrawrequest()}
                    data-bs-dismiss="modal"
                  >
                    Yes
                  </button>
                  <button
                    class="btn btn-dark"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </>
              ) : (
                <>
                  <button
                    class="btn btn-dark"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="page-body-wrapper">
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="page-title">
              <ToastContainer />
              <div class="row">
                <div class="col-sm-6">
                  <h3> Profile</h3>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <Link to={"/"}>
                        <i data-feather="home"></i>Dashboard
                      </Link>
                    </li>
                    {/* <li class="breadcrumb-item"> Profile</li> */}
                    <li class="breadcrumb-item active"> Profile</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="select2-drpdwn">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header pb-0">
                      {/* <h5 class="card-title"> Profile</h5> */}
                    </div>
                    {isLoading ? <Loader />
  : 
                    <div class="card-body o-hidden">
                      <form onSubmit={Check}>
                        <div class="mb-2">
                          <label for="name"> Name</label>
                          <input
                            class="form-control"
                            id="name"
                            type="text"
                            name="aname"
                            value={state.aname}
                            onChange={handler}
                            required="required"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="contact"> Email</label>
                          <input
                            class="form-control"
                            type="text"
                            name="email"
                            value={state.email}
                            // onBlur={Emaill}
                            onChange={handler}
                          />
                        </div>
                        <div class="mb-2">
                          <input
                            class="btn btn-primary"
                            type="submit"
                            value="Update Profile"
                          />
                        </div>
                      </form>
                    </div>
                    }
                  </div>
                </div>
              </div>
               
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefProfile;
