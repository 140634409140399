import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { REF_BASE_URL } from "./ref_config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RefTransWithdraw = ({ limit }) => {
  const [isLoading, setIsLoading] = useState(true);
  console.log(limit);
  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("rid");
  var websess = localStorage.getItem("web");
  // const [filteredData, setFilteredData] = useState(tableData);

  const [isActive, setIsActive] = useState(false);

  const ref = "MYCODE1234";

  const handleCopy = () => {
    const tempInput = document.createElement("input");
    tempInput.value = ref;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 2500);
  };

  //console.log(sesspass)
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    Web();
    abc();
    axios
      .post(REF_BASE_URL + "get_profile.php", { id: aid })
      .then(function (data) {
        console.log(data);
        setTableDatas(data.data.result);
        // setState({
        //   aname: data.data.result.name,
        //   email: data.data.result.email,
        // });
      });
  }, []);

  var sesspass = localStorage.getItem("pass");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");
  const history = useHistory();

  const [today, setToday] = useState("");
  const [months, setMonths] = useState("");
  const [years, setYears] = useState("");
  const [yearsall, setYearsall] = useState("");
  const Web = () => {
    axios
      .post(REF_BASE_URL + "earning.php", { id: aid })
      .then(function (data) {
        const abc = data.data.today;
        setToday(abc);

        setMonths(data.data.month);
        setYears(data.data.year);
        setYearsall(data.data.yearall);
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const abc = () => {
    axios
      .post(REF_BASE_URL + "get_transaction.php", { id: aid })
      .then(function (data) {
        console.log(data);
        if (data.data.message == "radha") {
          if (limit != 0) {
            setTableData(data.data.result.slice(0, limit));
          } else {
            setTableData(data.data.result);
          }
          handleSearchData();
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const gopro = (name) => {
    alert(name);
    history.push(`https://manglikgold.com/product-details/?${name}`);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row["date"],
      sortable: true,
      filterable: true,
    },

    {
      name: "Order Id",
      selector: (row) => "#" + row["orderid"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Order Product",
      selector: (row) => row["item_count"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Order Amount",
      selector: (row) =>"₹ "+ row["price"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Earning",
      selector: (row) =>"₹ "+ row["coupon"],
      sortable: true,
      filterable: true,
    },
    {
      name: "Person Name",
      selector: (row) => row["name"],
      sortable: true,
      filterable: true,
      onClick: (row) => {
        gopro(row.pname);
        return { style: { backgroundColor: "lightblue" } };
      },
    },
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    // filterData(e.target.value);
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [startdate, setStartdate] = useState("2024-01-01");
  const [enddate, setEnddate] = useState(formattedDate);

  const handleSearchData = () => {
    // filterData(searchText);
  };

  // const filterData = (text) => {
  //   if(text === ""){
  //   const filtered = tableData.filter((item) => {
  //     const startDateMatch = startdate ? item.date >= startdate : true;
  //     const endDateMatch = enddate ? item.date <= enddate : true;
  //     const searchTextMatch = text
  //       ? item.name.toLowerCase().includes(text.toLowerCase()) ||
  //         item.orderid.toString().includes(text)
  //       : true;

  //     return startDateMatch && endDateMatch && searchTextMatch;
  //   });
  //   setFilteredData(filtered);
  // }else{
  //   setFilteredData(tableData)
  // }
  // };

  //   const filteredData = tableData.filter((item) =>
  //   item.name.toLowerCase().includes(searchText.toLowerCase()) ||
  //   item.orderid.toString().includes(searchText) ||
  //   item.id.toString().includes(searchText)

  // );

  const filteredData = tableData.filter((item) => {
    // Check if startdate and enddate are not null
    const isDateInRange =
      startdate && enddate
        ? new Date(item.date) >= new Date(startdate) &&
          new Date(item.date) <= new Date(enddate)
        : true;

    // Perform text search
    const searchTextMatch = searchText
      ? item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.orderid.toString().includes(searchText) ||
      item.coupon.toString().includes(searchText) ||
      item.price.toString().includes(searchText) ||
      item.id.toString().includes(searchText)
      : true;

    return isDateInRange && searchTextMatch;
  });

  //   setFilteredData(filtered);

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  //   onClick={() => deletes()}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <div class="col-sm-12 ">
              <div class="card" style={{ minHeight: "250px" }}>
                <div class="card-header pb-0">
                  <h5>Transactions</h5>
                  <span></span>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3">
                          <label>Start Date</label>
                          <input
                            type="date"
                            class="form-control"
                            onChange={(e) => setStartdate(e.target.value)}
                            value={startdate}
                          />
                        </div>
                        <div class="col-md-3">
                          <label>End Date</label>
                          <input
                            type="date"
                            class="form-control"
                            onChange={(e) => setEnddate(e.target.value)}
                            value={enddate}
                          />
                        </div>

                        {/* <div class="col-md-1">
                    <label style={{ color: "transparent" }}>End Date</label>
                    <input
                      type="button"
                      class="btn btn-primary"
                      value="search"
                      onClick={handleSearchData}
                    /> 
                  </div>*/}
                      </div>

                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={filteredData}
                          pagination
                          subHeader
                          subHeaderComponent={
                            <input
                              type="text"
                              placeholder="Search"
                              class="form-control"
                              style={{ width: "200px" }}
                              value={searchText}
                              onChange={handleSearch}
                            />
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefTransWithdraw;
