import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import DataTable, { createTheme } from "react-data-table-component";
import { REF_BASE_URL } from "./ref_config";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RefTrans from "./refTrra";

const RefOrder = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [selectedId, setSelectedId] = useState("");
  const [tableData, setTableData] = useState([]);
  var sesspass = localStorage.getItem("pass");
  var aid = localStorage.getItem("rid");
  var websess = localStorage.getItem("web");
  const [filteredData, setFilteredData] = useState(tableData);

  const [isActive, setIsActive] = useState(false);

  const ref = "MYCODE1234";

  //console.log(sesspass)
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    Web();
    abc();
    axios
      .post(REF_BASE_URL + "get_profile.php", { id: aid })
      .then(function (data) {
        console.log(data);
        setTableDatas(data.data.result);
        // setState({
        //   aname: data.data.result.name,
        //   email: data.data.result.email,
        // });
      });
  }, []);

  var sesspass = localStorage.getItem("pass");
  var websess = localStorage.getItem("web");
  const web = localStorage.getItem("web");
  const history = useHistory();

  const [today, setToday] = useState("");
  const [months, setMonths] = useState("");
  const [years, setYears] = useState("");
  const [yearsall, setYearsall] = useState("");
  const Web = () => {
    axios
      .post(REF_BASE_URL + "earning.php", { id: aid })
      .then(function (data) {
        const abc = data.data.today;
        setToday(abc);

        setMonths(data.data.month);
        setYears(data.data.year);
        setYearsall(data.data.yearall);
        console.log(data);
      })
      .catch((error) => console.error(error));
  };

  const abc = () => {
    axios
      .post(REF_BASE_URL + "get_order.php", { id: aid })
      .then(function (data) {
        console.log(data);
        if (data.data.message == "radha") {
          setTableData(data.data.result);
          filterData("");
          setIsLoading(false);
        }
        setIsLoading(false);
      })

      .catch((error) => console.error(error));
    // setIsLoading(false);
  };

  const gopro = (name) => {
    alert(name);
    history.push(`https://manglikgold.com/product-details/?${name}`);
  };

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    // filterData(e.target.value);
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month starts from 0
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const [startdate, setStartdate] = useState("2024-01-01");
  const [enddate, setEnddate] = useState(formattedDate);

  const handleSearchData = () => {
    filterData(searchText);
  };

  const filterData = (text) => {
    const filtered = tableData.filter((item) => {
      const startDateMatch = startdate ? item.date >= startdate : true;
      const endDateMatch = enddate ? item.date <= enddate : true;
      const searchTextMatch = text
        ? item.name.toLowerCase().includes(text.toLowerCase()) ||
          item.orderid.toString().includes(text)
        : true;

      return startDateMatch && endDateMatch && searchTextMatch;
    });
    setFilteredData(filtered);
  };

  const margin = localStorage.getItem("margin");

  return (
    <div>
      <div class="page-body-wrapper">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">Are You Sure ?</div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  type="button"
                  //   onClick={() => deletes()}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div class="page-body" style={{ marginLeft: "320px" }}>
          <div class="container-fluid">
            <RefTrans />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefOrder;
