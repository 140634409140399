import React from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

import $ from "jquery";
import { useEffect, useState } from "react";
import { Book } from "react-feather";
import { DollarSign } from "react-feather";
import { FileText } from "react-feather";
import { GitPullRequest } from "react-feather";

const RefHeader = () => {
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [loandata, setLoandata] = useState(false);

  const Logout = () => {
    // localStorage.setItem("user", "");
    // localStorage.setItem("token", "");
    // localStorage.setItem("pass", "");
    localStorage.setItem("rid", "");
    // localStorage.setItem("margin", "");
    // localStorage.setItem("web", "");
    // window.location.reload(false);
    setTimeout(() => history.push("/"), 100);
  };

  console.log(localStorage.getItem("rid"));
  const sessid = localStorage.getItem("rid");
  if (sessid === null || sessid === "") {
    history.push("/"); // Redirect to login page
  }

  console.log(sessid);

  var sesspass = localStorage.getItem("user");

  const Dark = () => {
    $(".page-header").removeClass("close_icon");
    $(".sidebar-wrapper").removeClass("close_icon");
  };

  const Open = () => {
    var abc = document.getElementById("page-header").className;
    const currentURL = window.location.href;
    const aaa = currentURL.split("/");
    const url = aaa[3];

    if (abc == "page-header container-fluid close_icon") {
      console.log("close");
      localStorage.setItem("margin", "320px");
      $(".page-header").removeClass("close_icon");
      $(".sidebar-wrapper").removeClass("close_icon");
      $(".page-body").removeClass("side");
      // window.location.reload();
      setTimeout(() => history.push("/" + url), 0);
    } else {
      localStorage.setItem("margin", "96px");
      $(".page-body").addClass("side");
      $(".page-header").addClass("close_icon");
      $(".sidebar-wrapper").addClass("close_icon");
      // window.location.reload();

      setTimeout(() => history.push("/" + url), 0);
    }
  };

  const Openpage = (page) => {
    var abc = document.getElementById("page-header").className;
    const currentURL = window.location.href;
    const aaa = currentURL.split("/");
    const url = aaa[3];

    if (abc == "page-header container-fluid close_icon") {
      console.log("close");
      localStorage.setItem("margin", "320px");
      $(".page-header").removeClass("close_icon");
      $(".sidebar-wrapper").removeClass("close_icon");
      $(".page-body").removeClass("side");
      // window.location.reload();
      // setTimeout(() => history.push("/" + url), 0);
    } else {
      localStorage.setItem("margin", "96px");
      $(".page-body").addClass("side");
      $(".page-header").addClass("close_icon");
      $(".sidebar-wrapper").addClass("close_icon");

      // setTimeout(() => history.push("/" + url), 0);
    }
    history.push(page);

    // window.location.reload();
  };
  const margin = localStorage.getItem("margin");
  useEffect(() => {
    var modes = localStorage.getItem("mode");
    setStatus(modes);
    $(".aabb").addClass(modes);
    const type = localStorage.getItem("type");
    if (type == "1") {
      setLoandata(true);
    }
    const sessTime = localStorage.getItem("time");
    const currentTimestamp = Math.floor(Date.now() / 1000); // Divide by 1000 to get seconds
  }, []);

  const [isChecked, setChecked] = useState(false);

  const Mode = (e) => {
    // //console.log(e.target.class + "6666666")
    var abc = document.getElementById("mode").class;
    if (abc == "fa fa-moon-o") {
      localStorage.setItem("mode", "dark-only");

      $("#mode").removeClass("fa fa-moon-o");
      $("#mode").addClass("fa fa-lightbulb-o");
      $(".aabb").addClass("dark-only");
    } else {
      localStorage.setItem("mode", "");
      $("#mode").removeClass("fa fa-lightbulb-o");
      $("#mode").addClass("fa fa-moon-o");
      $(".aabb").removeClass("dark-only");
    }
    // //console.log(abc)
  };

  return (
    <div>
      <div class="page-header container-fluid" id="page-header">
        <div
          class="header-wrapper   m-0 "
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="toggle-sidebar" onClick={Open}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-align-center status_toggle middle sidebar-toggle"
            >
              <line x1="18" y1="10" x2="6" y2="10"></line>
              <line x1="21" y1="6" x2="3" y2="6"></line>
              <line x1="21" y1="14" x2="3" y2="14"></line>
              <line x1="18" y1="18" x2="6" y2="18"></line>
            </svg>

            {/* <img src="assets/images/next.png" onClick={Open} alt="right" /> */}
          </div>
          <div class="back-btn">
            <img src="assets/images/left.png" onClick={Open} alt="left" />
          </div>

          <div
            class="left-header horizontal-wrapper ps-0"
            style={{ margin: "auto", alignItems: "center" }}
          >
            {/* <div class="btn-group btn-group-toggle mb-3" style={{ borderRadius: "28px", boxShadow: "2px 2px 2px 0 rgba(0, 0, 0,0.5)", border: "5px solid red" }}>
                            <button id="light" type="button" style={{ backgroundColor: "#fff", color: "#e9252e", width: "150px", fontSize: "18px" }} onClick={handleToggle} class=" btn pr-4 pl-4 border-0">Mandal</button>
                            <button id="dark" type="button" style={{ backgroundColor: "red", color: "#fff", width: "150px" }} onClick={handleDhan} class="active btn pr-4 pl-4 border-0">DhanVarsha</button>
                        </div>  */}
          </div>
          <div class="nav-right  pull-right right-header ps-0">
            <ul class="nav-menus">
              <li class="profile-nav onhover-dropdown p-0 me-0">
                <div class="d-flex profile-media">
                  <img
                    class="b-r-50"
                    style={{ height: "40px", width: "40px" }}
                    src="assets/images/MLOGO.png"
                    alt="loading"
                  />
                  <div class="flex-grow-1">
                    <span>{localStorage.getItem('rname')}</span>
                    <p class="mb-0 font-roboto">
                      agent <i class="middle fa fa-angle-down"></i>
                    </p>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                  <li>
                    <Link to={"/myprofile"}>
                      <i data-feather="user"></i>
                      <span>Profile </span>
                    </Link>
                  </li>
                  {/* <li><Link to={'/password'}><i data-feather="mail"></i><span>Change Password</span></Link></li> */}
                  {loandata ? (
                    ""
                  ) : (
                    <li>
                      <Link onClick={() => Logout()}>
                        <i data-feather="log-in"> </i>
                        <span>Log out</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
          <script class="result-template" type="text/x-handlebars-template">
            <div class="ProfileCard u-cf">
              <div class="ProfileCard-avatar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-airplay m-0"
                >
                  <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div class="ProfileCard-details">
                <div class="ProfileCard-realName">name</div>
              </div>
            </div>
          </script>
          <script class="empty-template" type="text/x-handlebars-template">
            <div class="EmptyMessage">
              Your search turned up 0 results. This most likely means the
              backend is down, yikes!
            </div>
          </script>
        </div>
      </div>

      <div class="page-body-wrapper">
        <div class="sidebar-wrapper" id="page-head">
          <div class="logo-wrapper">
            <Link to={"/"}>
              <img
                class="img-fluid for-light"
                style={{ height: "55px", width: "auto" }}
                src="../assets/images/MGLOGO.png"
                alt="load"
              />
            </Link>
            <div class="back-btn" onClick={Open}>
              <img src="assets/images/left.png" alt="leftchvjhvj" />
            </div>
            <div class="toggle-sidebar" onClick={Open}>
              <img src="assets/images/next.png" alt="right" />
            </div>
          </div>
          <div class="logo-icon-wrapper">
            <Link to={"/"}>
              <img
                class="img-fluid"
                src="../assets/images/mmlogo.png"
                style={{ height: "45px", width: "45px" }}
                onClick={Open}
                alt="load"
              />
            </Link>
          </div>
          <nav class="sidebar-main">
            <div class="left-arrow disabled" id="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </div>
            <div id="sidebar-menu">
              <ul
                class="sidebar-links"
                id="simple-bar"
                data-simplebar="init"
                style={{ display: "block" }}
              >
                <div class="simplebar-wrapper" style={{ margin: "-40px" }}>
                  <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                  </div>
                  <div class="simplebar-mask">
                    <div
                      class="simplebar-offset"
                      style={{ right: "0px", bottom: "0px" }}
                    >
                      <div
                        class="simplebar-content-wrapper"
                        style={{ height: "100%", overflow: "hidden scroll" }}
                      >
                        <div
                          class="simplebar-content"
                          style={{ margin: "10px" }}
                        >
                          <li class="menu-box" style={{ margin: "10px" }}>
                            <ul>
                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav "
                                  // to={"/mydashboard"}
                                  onClick={() => Openpage("/mydashboard")}
                                >
                                  <FileText />
                                  <span>Dashboard</span>
                                </Link>
                              </li>
                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav "
                                  // to={"/reforder"}
                                  onClick={() => Openpage("/reforder")}
                                >
                                  <DollarSign />
                                  <span>Orders</span>
                                </Link>
                              </li>
                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav "
                                  // to={"/reftransaction"}
                                  onClick={() => Openpage("/reftransaction")}
                                >
                                  <Book />
                                  <span>Transaction</span>
                                </Link>
                              </li>
                              <li class="sidebar-list">
                                <Link
                                  class="sidebar-link sidebar-title link-nav "
                                  // to={"/reftwithdraw"}
                                  onClick={() => Openpage("/reftwithdraw")}
                                >
                                  <GitPullRequest />
                                  <span>Withdraw</span>
                                </Link>
                              </li>
                              {/* <li class="sidebar-list"><Link class="sidebar-link sidebar-title link-nav " to={'/myprofile'}><GitPullRequest /><span>Profile</span></Link></li> */}
                            </ul>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="simplebar-placeholder"
                    style={{ width: "auto", height: "2662px" }}
                  ></div>
                </div>
                <div
                  class="simplebar-track simplebar-horizontal"
                  style={{ visibility: "hidden" }}
                >
                  <div
                    class="simplebar-scrollbar"
                    style={{ width: "0px", display: "none" }}
                  ></div>
                </div>
                <div
                  class="simplebar-track simplebar-vertical"
                  style={{ visibility: "visible" }}
                >
                  <div
                    class="simplebar-scrollbar"
                    style={{
                      height: "304px",
                      transform: "translate3d(0px, 0px, 0px)",
                      display: "block",
                    }}
                  ></div>
                </div>
              </ul>
            </div>
            <div class="right-arrow" id="right-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default RefHeader;
