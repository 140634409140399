import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { REF_BASE_URL } from "./ref_config";
import { data } from "jquery";

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { Button } from "bootstrap";

const RefRegister = () => {
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [rname, setRname] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const [last, setLast] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");

  const ValidPass = () => {
    if (password !== cpassword) {
      setTimeout(
        () => setErrors("Confirm Password must be same as password"),
        100
      );
    } else {
      setErrors("");
    }
  };

  const firebaseConfig = {
    apiKey: "AIzaSyCO1-G26sBW3w981UwdFnl_PNcBtzj6Zyw",
    authDomain: "manglikgold-c3dc8.firebaseapp.com",
    projectId: "manglikgold-c3dc8",
    storageBucket: "manglikgold-c3dc8.appspot.com",
    messagingSenderId: "297533616712",
    appId: "1:297533616712:web:db2fab633c7f4053391e7d",
    measurementId: "G-XX1Q8V71WG",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState(false);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            sentOtp();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  const sentOtp = (e) => {
    onCaptchVerify();

    // e.preventDefault();
    console.log("trigger");
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, `+91${mobile}`, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("check this", confirmationResult);
        // setShowOTP(true);
        console.log("OTP sended successfully!");
        const lastFourDigits = mobile.substring(mobile.length - 4);
        setLast(lastFourDigits); // Output: 7890
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  function handleVerifyOTP(e) {
    // setLoading(true);
    e.preventDefault()
    setLoading(false);

    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res?.user);
        RegisterUser();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid OTP")
        setLoading(false);
      });
  }

  const deletes = () => {
    if (!selected) {
      toast.error("Please Accept Terms And Conditions");
    }
  };
  const [loading, setLoading] = useState(false);

  const Checkuser = (e) => {
    e.preventDefault();
    if (!mobile || !rname || !email || !dob) {
      return toast.error("All fields  are required!");
    } else if (email.indexOf("@") == -1 || email.lastIndexOf(".") == -1) {
      return toast.error("Please enter a valid Email address!");
    } else if (dob > new Date().toISOString().substr(0, 10)) {
      return toast.error("Date of birth can not be greater than today's date!");
    } else if (!selected) {
      return toast.error("Accept terms and conditions");
    } else {
      setLoading(false);

      const data = JSON.stringify({
        mobile: mobile,
        token: token,
        name: rname,
        dob: dob,
        email: email,
        password: password,
      });

      axios.post(REF_BASE_URL + "reg_check.php", data).then(function (data) {
        console.log(data);
        if (data.data.message === "Invalid User or password") {
          toast.error("Invalid Mobile number and Password.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(true);
        } else if (data.data.message == "invalid") {
          toast.warn("This Mobile number is already registered!");
          setLoading(true);
        } else if (data.data.message == "radha") {
          sentOtp();
        }
      });
      setLoading(true);
    }
  };
  const [langs, setLangs] = useState("english");
  const RegisterUser = () => {
    const data = JSON.stringify({
      mobile: mobile,
      token: token,
      name: rname,
      dob: dob,
      email: email,
      password: password,
    });

    axios.post(REF_BASE_URL + "register.php", data).then(function (data) {
      console.log(data);
      if (data.data.message === "Invalid User or password") {
        toast.error("Invalid Mobile number and Password.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (data.data.message == "User already registered") {
        toast.warn("This user is already registered!");
      } else if (data.data.message == "radha") {
        setLast("");
        setMessage(
          "You are successfully registered . Please wait for admin approve yo. Continue with Login."
        );
      }
    });
  };

  return (
    <div>
      <body>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div className="row">
                  <h4>Terms & Conditions</h4>
                  <div className="col-md-4">
                    <p
                      className="m-3"
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        background: langs === "english" ? "red" : "gray",
                        color: "white",
                      }}
                      onClick={() => setLangs("english")}
                    >
                      English
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p
                      className="m-3"
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        background: langs === "hn" ? "red" : "gray",
                        color: "white",
                      }}
                      onClick={() => setLangs("hn")}
                    >
                      Hindi
                    </p>
                  </div>
                  <div className="col-md-4 col-xs-3 col-sm-3">
 
                    <p
                      className="m-3"
                      style={{
                        textAlign: "center",
                        border: "1px solid black",
                        paddingLeft: "10px",
                        background: langs === "gj" ? "red" : "gray",
                        color: "white",
                      }}
                      onClick={() => setLangs("gj")}
                    >
                      Gujarati
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  {
                    <div
                      className="form-control"
                      // style={{
                      //   margin: "4px, 4px",
                      //   padding: "4px",
                      //   height: "190px",
                      //   background: "white",
                      //   overflowY: "auto",
                      //   textAlign: "justify",
                      //   // border: "1px solid black",
                      // }}
                    >
                      {langs === "english" && (
                        <p>
                          Hello,
                          <br />
                          {/* You can earn money from home in our online
                          application.
                          <br /> */}
                          After joining our scheme, it is compulsory to leave
                          one day and post one day in WhatsApp status, Facebook
                          and Instagram and post and story.
                          <br />
                          After joining the application you will get a reference
                          code. Based on this Refrence Code, who will buy from
                          the application, you will get a huge discount and the
                          same discount amount. (Both buyer and seller will
                          benefit.) <br />
                          The details are as follows
                          
                          <table className="table table-responsive">
                            <thead>
                                <tr>
                                  <td>Total Price</td>
                                  <td>Buyer</td>
                                  <td>Seller</td>
                                </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1 to 1999</td>
                                <td>100</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>2000 to 2499</td>
                                <td>125</td>
                                <td>125</td>
                              </tr>
                              <tr>
                                <td>2500 to 2999</td>
                                <td>150</td>
                                <td>150</td>
                              </tr>
                              <tr>
                                <td>3000 to 4999</td>
                                <td>200</td>
                                <td>200</td>
                              </tr>
                              <tr>
                                <td>5000 to 8999</td>
                                <td>300</td>
                                <td>300</td>
                              </tr>
                              <tr>
                                <td>9000 to 12999</td>
                                <td>400</td>
                                <td>400</td>
                              </tr>
                              <tr>
                                <td>13000 to any price</td>
                                <td>500</td>
                                <td>500</td>
                              </tr> 

                            </tbody>
                          </table>
                         <br/>
                          1500/- separately on sale of 50 orders in a month.
                          <br />
                          Based on this calculation you will ex. 150 × 50 = 7500
                          + 1500(extra) = 9000/- minimum and maximum 50000/- can
                          earn from home.
                          <br />
                          Payment will reach your account after 15 days of
                          order.
                          <br />
                          Every order, sale + amount will be accounted for in
                          your ID.
                          <br />
                          Thank you very much for joining Manglik Gold.
                          <br />
                        </p>
                      )}
                      {langs === "hn" && (
                        <p>
                          प्रणाम <br />
                          {/* हमारी ऑनलाइन एप्लीकेशन से घर बैठे आप पैसा कमा सकते है। */}
                          {/* <br /> */}
                          हमारी यह स्कीम के मुताबिक इसमें जुड़ जाने के बाद आपको
                          संपूर्ण रुप से एक दिन छोड़ के एक दिन अपने मोबाइल के
                          व्हाट्सएप में स्टेटस फेसबुक और इंस्ट्राग्राम में पोस्ट
                          और स्टोरी अवश्य रखनी होगी।
                          <br />
                          एप्लिकेशन में जुड़ जाने के बाद आपको एक refrence code
                          मिलेगा इस refrence code के मुताबिक एप्लीकेशन से जो भी
                          खरीदी करेगा उस ग्राहक को बड़ा डिस्काउंट और उसी
                          डिस्काउंट के पैसे आपको मिलेंगे ( खरीदनार ग्राहक और
                          व्यापारी दोनो को लाभ मिलेगा)
                          <table className="table table-responsive">
                            <thead>
                                <tr>
                                  <td>टोटल कीमत</td>
                                  <td>ख़रीदार</td>
                                  <td>विक्रेता</td>
                                </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1 to 1999</td>
                                <td>100</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>2000 to 2499</td>
                                <td>125</td>
                                <td>125</td>
                              </tr>
                              <tr>
                                <td>2500 to 2999</td>
                                <td>150</td>
                                <td>150</td>
                              </tr>
                              <tr>
                                <td>3000 to 4999</td>
                                <td>200</td>
                                <td>200</td>
                              </tr>
                              <tr>
                                <td>5000 to 8999</td>
                                <td>300</td>
                                <td>300</td>
                              </tr>
                              <tr>
                                <td>9000 to 12999</td>
                                <td>400</td>
                                <td>400</td>
                              </tr>
                              <tr>
                                <td>13000 to any price</td>
                                <td>500</td>
                                <td>500</td>
                              </tr> 

                            </tbody>
                          </table>
                          <br/>
                          उपरोक्त दिखाए गए कोष्ठक अनुसार आपको पैसे दिए जायेंगे ।
                          <br />
                          और अगर आप हर महिने में 50 ऑडर लाते हो तो आपको 1500 तक
                          अलग से बोनस दिया जायेगा <br />
                          Ex : 150 * 50 = 7500 अगर एक महिने मे 50 ऑडर हे तो 1500
                          बोनस इस हिसाब से 7500 + 1500 = 9000 घर बैठे कमा सकते
                          हों।
                          <br />
                          आपके पैसे ऑडर आने के 15 दीन बाद आपके खाते मे भेजे
                          जाएंगे।
                          <br />
                          हर ऑडर एवम बेची हुई चीज़ और आपके पैसे की जानकारी आपकी
                          ID में दिखाई जायेगी।
                          {/* 🆔  */}
                          <br />
                          आप मांगलिक गोल्ड के साथ जुड़ गए इसके लिए आपको दिल से
                          धन्यवाद।
                          <br />
                        </p>
                      )}
                      {langs === "gj" && (
                        <p>
                          <b> પ્રણામ, </b>
                          {/* <br /> */}
                          {/* અમારી ઓનલાઇન એપ્લિકેશનમાં ઘરે બેઠા પૈસા કમાઈ શકશો. */}
                          <br />
                          અમારી સ્કીમમાં જોડાયા પછી ફરજીયાત એક દિવસ છોડી એક દિવસ
                          વોટસઅપ માં સ્ટેટસ, ફેસબુક અને ઈન્સ્ટાગ્રામમાં પોસ્ટ
                          અને સ્ટોરી મૂકવાની રહેશે.
                          <br />
                          એપ્લિકેશનમાં જોડાયા પછી એક Refrence Code મળશે. આ
                          Refrence Code ના આધારે એપ્લિકેશનથી જે ખરીદી કરશે તેને
                          ભવ્ય ડિસ્કાઉન્ટ અને તે જ ડિસ્કાઉન્ટ ના રૂપિયા તમને
                          મળશે. (ખરીદનાર અને વેચનાર બંને ને લાભ મળશે.)
                          <br /> તેની વિગત
                          નીચે મુજબ છે
                      
                          <table className="table table-responsive">
                            <thead>
                                <tr>
                                  <td>કુલ કિંમત</td>
                                  <td>ખરીદનાર</td>
                                  <td>વેચનાર</td>
                                </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1 to 1999</td>
                                <td>100</td>
                                <td>100</td>
                              </tr>
                              <tr>
                                <td>2000 to 2499</td>
                                <td>125</td>
                                <td>125</td>
                              </tr>
                              <tr>
                                <td>2500 to 2999</td>
                                <td>150</td>
                                <td>150</td>
                              </tr>
                              <tr>
                                <td>3000 to 4999</td>
                                <td>200</td>
                                <td>200</td>
                              </tr>
                              <tr>
                                <td>5000 to 8999</td>
                                <td>300</td>
                                <td>300</td>
                              </tr>
                              <tr>
                                <td>9000 to 12999</td>
                                <td>400</td>
                                <td>400</td>
                              </tr>
                              <tr>
                                <td>13000 to any price</td>
                                <td>500</td>
                                <td>500</td>
                              </tr> 

                            </tbody>
                          </table>
                          <br/>
                          મહિનામાં 50 ઓર્ડર વેચાણ પર 1500/- અલગથી મળશે.
                          <br />
                          આ ગણતરીના આધારે તમે દર મહિને ex. 150 × 50 = 7500 +
                          1500(extra) = 9000/- ઓછામાં ઓછા અને વધુમાં વધુ 50000/-
                          ઘરે બેઠા કમાઈ શકશો.
                          <br />
                          પેમેન્ટ ઓર્ડરના 15 દિવસ પછી તમારા ખાતામાં આવશે.
                          <br />
                          દરેક ઓર્ડર, વેચાણ + રકમ નો હિસાબ તમારી ID માં મળશે.
                          <br />
                          તમે માંગલિક ગોલ્ડ સાથે જોડાયા તે માટે તમારો દિલ થી
                          આભાર.
                          <br />
                        </p>
                      )}
                    </div>
                  }
                </div>
                <input
                        type="checkbox"
                        onClickCapture={() => setSelected(!selected)}
                        checked={selected}
                      />
                      <label>
                        {" "}
                        &nbsp; I agree to terms and conditions of manglik gold
                      </label>

              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-dark me-auto"
                  type="button"
                  data-bs-dismiss="modal"
                  style={{ float: "left", marginLeft: "0px !important" }}
                >
                  Cancel
                </button>
                {selected ? (
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    Accept
                  </button>
                ) : (
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => deletes()}
                  >
                    Accept
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <div class="login-card">
                <div>
                  <ToastContainer />

                  <div class="logo">
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src="../assets/images/MLOGO.png"
                      alt="Mangalik Gold"
                    />
                  </div>
                  <div class="login-main">
                    {last ? (
                      <>
                        {/* <div className="col" md={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "20px",
                            }}
                          >
                            <img
                              src="/assets/images/jewellery/otp.png"
                              alt=""
                              width={400}
                            />
                          </div>
                        </div> */}
                        {/* <div md={6}> */}
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src="/assets/images/favicon/MLOGO.png"
                              alt=""
                              width={100}
                            />
                          </div> */}
                          <form class="theme-form">

                          <p style={{textAlign:"center"}}> We send you OTP on <br/> xxxxxx{last}</p>
                          <div className="form-group">
                            <label className="form-label" for="otp">
                              OTP
                            </label>
                            <input
                              type="text"
                              maxLength={6}
                              className="form-control"
                              id="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                              required=""
                            />
                          </div>
                          {error}
                          <br />
                          <button
                            className={`btn btn-primary btn-block w-100 ${loading ? "disabled":""}`}
                            onClick={handleVerifyOTP}
                          >
                            Verify OTP
                          </button>
                          {/* <p className="mt-2" onClick={handleSendOTP}>
                            Resend OTP
                          </p> */}
                          </form>
                        {/* </div> */}
                      </>
                    ) : message ? (
                      <>
                        {" "}
                        {message}{" "}
                        <p class="mt-4 mb-0 text-center">
                          <Link class="btn btn-primary ms-2" to="/">
                            Login
                          </Link>
                        </p>
                      </>
                    ) : (
                      <>
                        <form class="theme-form" onSubmit={(e) => Checkuser(e)}>
                          <div id="recaptcha-container"></div>

                          {message}
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="col-form-label">Name</label>
                                <div class="form-input position-relative">
                                  <input
                                    class="form-control"
                                    type="text"
                                    value={rname}
                                    onChange={(e) => {
                                      setRname(e.target.value);
                                    }}
                                    placeholder="Name"
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <label class="col-form-label">Mobile No</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  value={mobile}
                                  maxLength={10}
                                  onChange={(e) => {
                                    setMobile(e.target.value);
                                  }}
                                  required=""
                                  placeholder="Mobile No"
                                />
                              </div>

                              <div class="form-group">
                                <label class="col-form-label">Email </label>
                                <input
                                  class="form-control"
                                  type="text"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                  required=""
                                  placeholder="Email"
                                />
                              </div>
                              <div class="form-group">
                                <label class="col-form-label">
                                  Date Of Birth
                                </label>
                                <div class="form-input position-relative">
                                  <input
                                    class="form-control"
                                    type="date"
                                    value={dob}
                                    onChange={(e) => {
                                      setDob(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <p class="mt-4 mb-0 text-center">
                            Our terms and conditions
                            <Link
                              data-bs-toggle="modal"
                              data-original-title="test"
                              data-bs-target="#exampleModal"
                            >
                              &nbsp; Read Now
                            </Link>
                          </p>

                          <div class="form-group mb-0">
                            <div class="text-end mt-3">
                              {loading == false ? (
                                <>
                                  <input
                                    type="submit"
                                    class="btn btn-primary btn-block w-100"
                                    value="Register"
                                  />
                                </>
                              ) : (
                                <>
                                  <input
                                    type="submit"
                                    class="btn btn-primary btn-block w-100 disabled"
                                    value="Register"
                                  />
                                </>
                              )}
                            </div>
                          </div>

                          <p class="mt-4 mb-0 text-center">
                            Already have account?
                            <Link class="ms-2" to="/">
                              Login
                            </Link>
                          </p>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default RefRegister;
