import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


// if (localStorage.getItem('aid') == null || localStorage.getItem('aid') == '') {
//   ReactDOM.render(

//     <React.StrictMode>
//       <Login />
//       <ProLogin/>
//     </React.StrictMode>,
//     document.getElementById('root')
//   );

// } else {

  ReactDOM.render(

    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
// }

