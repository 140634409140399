import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { REF_BASE_URL } from "./ref_config";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

const RefLogin = () => {
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [last, setLast] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const sessid = localStorage.getItem("rid");

  useEffect(() => {
    if (sessid != null && sessid != "") {
      history.push("/mydashboard"); // Redirect to login page
    }
  })

  const firebaseConfig = {
    apiKey: "AIzaSyCO1-G26sBW3w981UwdFnl_PNcBtzj6Zyw",
    authDomain: "manglikgold-c3dc8.firebaseapp.com",
    projectId: "manglikgold-c3dc8",
    storageBucket: "manglikgold-c3dc8.appspot.com",
    messagingSenderId: "297533616712",
    appId: "1:297533616712:web:db2fab633c7f4053391e7d",
    measurementId: "G-XX1Q8V71WG",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [message, setMessage] = useState("");

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            sentOtp();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  const sentOtp = (e) => {
    console.log("trigger");
    if (!mobile) {
      toast.error("Please Provide Mobile number and Password.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      onCaptchVerify();
      setLoading(true);

      // e.preventDefault();
      console.log("trigger");
      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, `+91${mobile}`, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          console.log("check this", confirmationResult);
          // setShowOTP(true);
          // toast.success("OTP sended successfully!");
          console.log("OTP sended successfully!");
          const lastFourDigits = mobile.substring(mobile.length - 4);
          setLast(lastFourDigits); // Output: 7890
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  function handleVerifyOTP(e) {
    e.preventDefault();
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res?.user);
        loginuser();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid OTP");
        // setError("Invalid OTP")
        setLoading(false);
      });
  }

  const Checkuser = (e) => {
    e.preventDefault();
    if (mobile.length != 10) {
      toast.error("Please enter valid mobile number");
      return;
    } else {
      setLoading(true);

      axios
        .post(REF_BASE_URL + "check.php", {
          mobile: mobile,
        })
        .then(function (data) {
          console.log(data);
          if (data.data.message == "invalid") {
            toast.error("You are not registered ! Register first.");
            setLoading(false);

          } else if(data.data.message == "auth"){
            setLoading(false);

            toast.error("Admin not approved you wait till admin approve you.");
          }else if (
            data.data.message == "radha" 
          ) {
            sentOtp();

            // setLoading(false)s;
          }
        });
    }
  };

  const loginuser = (e) => {
    // e.preventDefault();

    axios
      .post(REF_BASE_URL + "login.php", { mobile: mobile })
      .then(function (data) {
        console.log(data);
        if (data.data.message === "Invalid") {
          toast.error(
            "Invalid Mobile number and Password or admin not approved you",
            { position: toast.POSITION.TOP_RIGHT }
          );
          setLoading(false);
        } else if (data.data.message == "radha") {
          toast.success("Successfully Logged In....", {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.setItem("rid", data.data.id);
          localStorage.setItem("rname", data.data.name);
          setTimeout(() => history.push("/mydashboard"), 0);
          setLoading(false);
        }
      });
  };

  // const change = () => {
  //   setTimeout(() => history.push("/"), 0);
  //   // window.location.reload(false);
  // };

  // const agent = () => {
  //   setTimeout(() => history.push("/"), 0);
  // };

  // const ecom = () => {
  //   setTimeout(() => history.push("/mylogin"), 0);
  //   // window.location.reload(false);
  // };

  return (
    <div>
      <body>
        <div class="container-fluid p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <div class="login-card">
                <div>
                  <ToastContainer />
                  <div id="recaptcha-container"></div>

                  <div class="logo">
                    <img
                      style={{ height: "150px", width: "150px" }}
                      src="../assets/images/MLOGO.png"
                      alt="Mangalik Gold"
                    />
                    <h3>Agent Login</h3>
                  </div>
                  <div class="login-main">
                    {last ? (
                      <>
                        <form class="theme-form">
                          <p style={{ textAlign: "center" }}>
                            We send you OTP on <br />
                            xxxxxx{last}
                          </p>

                          <div className="form-group">
                            <label className="form-label" for="otp">
                              OTP
                            </label>
                            <input
                              type="text"
                              maxLength={6}
                              className="form-control"
                              id="otp"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                              required=""
                            />
                          </div>

                          
                          {error}
                          
                          <br />
                          <button
                            className={`btn btn-primary btn-block w-100 ${
                              loading == true ? "disabled" : ""
                            }`}
                            onClick={handleVerifyOTP}
                          >
                            Verify OTP
                          </button>
                        </form>
                        <p className="mt-2" onClick={sentOtp}>
                          Resend OTP
                        </p>
                      </>
                    ) : (
                      <>
                        <form class="theme-form" onSubmit={(e) => Checkuser(e)}>
                          <div class="form-group">
                            <label class="col-form-label">Mobile No</label>
                            <input
                              class="form-control"
                              type="number"
                              value={mobile}
                              maxLength={10}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              required=""
                              placeholder="Please Enter Mobile No"
                            />
                          </div>

                          <div class="form-group mb-0">
                            {loading == false ? (
                              <>
                                <input
                                  type="submit"
                                  class="btn btn-primary btn-block w-100"
                                  value="Login"
                                />
                              </>
                            ) : (
                              <>
                                <input
                                  type="submit"
                                  class="btn btn-primary btn-block w-100 disabled"
                                  value="Login"
                                />
                              </>
                            )}
                          </div>

                          <p class="mt-4 mb-0 text-center">
                            Don't have account?
                            <Link class="ms-2" to="/refregister">
                              Create Account
                            </Link>
                          </p>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div class="row" style={{ textAlign: "center" }}>
                <div class="col-4" style={{ color: "red", textAlign: "start" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => agent()}>
                    Agent Login{" "}
                  </p>
                </div>
                <div class="col-4" style={{ color: "red" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => change()}>
                    Mandal Login{" "}
                  </p>
                </div>
                <div class="col-4" style={{ textAlign: "end", color: "red" }}>
                  <p style={{ fontSize: "20px" }} onClick={() => ecom()}>
                    ECommerce Login{" "}
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default RefLogin;
