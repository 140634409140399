import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import RefHeader from "./reference/refHeader";
import RefLogin from "./reference/refLogin";
import RefTransaction from "./reference/refDashboard";
import RefProfile from "./reference/ref_profile";
import RefOrder from "./reference/refOrder";
import RefTransWithdraw from "./reference/refTransactions";
import RefWithdraw from "./reference/ref_withdraw";
import RefRegister from "./reference/refRegister";
import DailyPassword from "./reference/dailypass";
// import Agents from "./pages/agents";
// import KYC from "./pages/kyc";
// import AgentTrans from "./pages/agent_trans";

function App() {
  const ab = localStorage.getItem("aid");

  return (
    <Router>
      <div>
        <Switch>
          {/* <Route exact path="/><GoogleTranslate /> </Route> */}

          <Route exact path="/">
            <RefLogin />
          </Route>
          <Route path="/mydashboard">
            <RefHeader />
            <RefTransaction />
          </Route>
          <Route path="/myprofile">
            <RefHeader />
            <RefProfile />
          </Route>
          <Route path="/reforder">
            <RefHeader />
            <RefOrder />
          </Route>
          <Route path="/reftransaction">
            <RefHeader />
            <RefTransWithdraw />
          </Route>
          <Route path="/reftwithdraw">
            <RefHeader />
            <RefWithdraw />
          </Route>
          <Route path="/refregister">
            <RefRegister />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
